import initAnalytics from '@renofi/utilities/src/segment';
import {initLogRocket} from '@renofi/utilities/src/logrocket2';
import {initAccessibe, initSentry} from '@renofi/utilities/src/analytics';

export default async ({config}) => {
  const {DEVELOPMENT, PRODUCTION, LOGROCKET_ID, SEGMENT_KEY, SEGMENT_URL} =
    config;

  if (!DEVELOPMENT && SEGMENT_KEY) {
    initAnalytics({segmentKey: SEGMENT_KEY, baseUrl: SEGMENT_URL});
  }

  if (PRODUCTION) {
    initLogRocket(LOGROCKET_ID);
    initSentry();
    initAccessibe();
  }
};
