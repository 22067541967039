import React from 'react';

import PropTypes from 'prop-types';

import {Text} from '@renofi/components-internal';

import {RedBanner, ButtonLink} from './styled';

const OutdatedBrowser = () => (
  <RedBanner>
    <div className="container">
      <Text>
        Your web browser is out of date and our applications may not support it
        fully. Update your browser for improved security and a better
        experience.
        <ButtonLink blank href="http://outdatedbrowser.com">
          Update your browser
        </ButtonLink>
      </Text>
    </div>
  </RedBanner>
);

OutdatedBrowser.propTypes = {
  children: PropTypes.node,
};

export default OutdatedBrowser;
