import {once} from 'ramda';
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import detector from 'i18next-browser-languagedetector';

import en from './localization/en';

export default once(() =>
  i18n
    .use(detector)
    .use(initReactI18next)
    .init({
      resources: {
        en,
      },
      lng: 'en',
      fallbackLng: 'en',
      debug: false,
      interpolation: {
        escapeValue: false,
      },
    }),
);
