import styled from '@emotion/styled';

import {Text} from '@renofi/components-internal';
import {maxSmall} from '@renofi/theme/src/breakpoints';

export const Title = styled(Text)({
  fontSize: 26,
  lineHeight: '31px',
  color: '#333333',
  padding: '30px 30px 25px 30px',
  borderBottom: `solid 1px #E5E5E5`,
  width: '100%',
  textAlign: 'center',
  [maxSmall]: {
    padding: '10px 20px 20px 20px',
    fontSize: 20,
    lineHeight: '22px',
    letterSpacing: 0,
    width: 'calc(100% + 30px)',
  },
});
