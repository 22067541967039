import styled from '@emotion/styled';

import {Box, Container, Flex} from '@renofi/components-internal';
import {maxSmall} from '@renofi/theme/src/breakpoints';

import logo from './renofi.svg';

export const Wrapper = styled(Flex)({
  width: '100%',
  minHeight: '100vh',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#212F49',
  flexDirection: 'column',
  padding: '60px 0',
  [maxSmall]: {
    justifyContent: 'flex-start',
    minWidth: 340,
    padding: '0 10px',
  },
});

export const Logo = styled.div({
  height: 44,
  width: 160,
  backgroundImage: `url('${logo}')`,
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100%',
  marginBottom: 60,
  [maxSmall]: {
    marginTop: 30,
    marginBottom: 20,
    width: 120,
  },
});

export const Outer = styled(Box)({
  width: '50%',
  minWidth: 720,
  minHeight: 249,
  [maxSmall]: {
    width: '100%',
    minWidth: 'auto',
    marginBottom: 30,
    minHeight: 'auto',
  },
});

export const Inner = styled(Flex)({
  marginBottom: 35,
  minHeight: 249,
  flexDirection: 'column',
  alignItems: 'center',
  [maxSmall]: {
    minHeight: 'auto',
    marginBottom: 0,
  },
});

export const ShadowedBox = styled.div({
  backgroundColor: 'white',
  borderRadius: 16,

  [maxSmall]: {
    padding: 16,
  },
});

export const Column = styled(Container)({
  display: 'flex',
  justifyContent: 'center',

  [maxSmall]: {
    padding: 0,
  },
});
