import React from 'react';

import PropTypes from 'prop-types';

import {ContentBox} from './styled';

const Content = ({children, ...props}) => (
  <ContentBox {...props}>{children}</ContentBox>
);

Content.propTypes = {
  children: PropTypes.node,
};

export default Content;
