import React from 'react';

import PropTypes from 'prop-types';

import Check from '@renofi/icons/src/Check';

const CheckItem = ({children, selected}) => (
  <div
    css={{
      display: 'flex',
      alignItems: 'center',
      padding: '5px 0',
      fontSize: 13,
      lineHeight: '13px',
      fontWeight: 'bold',
      color: '#737373',
    }}>
    <Check
      color="#FFFFFF"
      css={{
        position: 'absolute',
        width: 10,
        marginLeft: 3,
      }}
    />
    <div
      css={{
        display: 'inline-block',
        borderRadius: 100,
        background: '#CCCCCC',
        ...(selected ? {background: '#13C427'} : []),
        width: 16,
        height: 16,
        marginRight: '7px',
        transition: 'background .6s',
      }}
    />
    {children}
  </div>
);

CheckItem.propTypes = {
  children: PropTypes.node,
  selected: PropTypes.bool,
};

export default CheckItem;
