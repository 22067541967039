import React from 'react';

import PropTypes from 'prop-types';

import {Link} from '@renofi/components-internal';
import {sendEvent} from '@renofi/utilities/src/analytics';

const Component = (props) => {
  const {href, onClick: propsOnClick, title, to} = props;

  const onClick = (e) => {
    sendEvent('LinkClick', {
      text: e.target.textContent || title,
      url: to || href,
    });
    if (propsOnClick) {
      propsOnClick(e);
    }
  };

  return <Link {...props} onClick={onClick} />;
};

Component.propTypes = {
  href: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string,
  to: PropTypes.string,
};

export default Component;
