import {useCallback, useEffect} from 'react';

import {sendEvent as sendAnalyticsEvent} from '@renofi/utilities/src/analytics/index';

export default function useTracking(trackingId, options) {
  const sendEvent = useCallback(sendAnalyticsEvent, []);

  useEffect(() => {
    sendEvent(trackingId, options);
  }, []);

  return {sendEvent};
}
