import styled from '@emotion/styled';

import {Link} from '@renofi/components-internal';
import {dangerRegular} from '@renofi/theme/src/colors';

export const RedBanner = styled.div({
  background: 'red',
  marginBottom: 40,
  width: '100%',
  textAlign: 'center',
  padding: '10px 0',
  color: 'white',
  display: 'none',
  '@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none)':
    {
      display: 'block',
    },
});

export const ButtonLink = styled(Link)({
  background: dangerRegular,
  padding: '7px 20px',
  borderRadius: 20,
  color: 'white',
  marginLeft: 20,
  ':hover': {
    color: 'white',
  },
});
