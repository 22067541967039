import {toCamelCase} from './string';

export const BORROWER_AGREEMENTS = [
  'accountTermsOfUse',
  'arbitrationAgreement',
  'limitationOfLiabilityDisclaimerOfWarranties',
  'appraisalFeeConsent',
  'lenderAccess',
];

export const COMMON_AGREEMENTS = ['electronicSignature'];

export const LENDER_AGREEMENTS = ['privacyPolicyAndGlba', 'termsOfService'];

export const CONTRACTOR_AGREEMENTS = [
  'contractorTermsOfService',
  'electronicSignature',
];

export function hasUserAcceptedAllAgreements(user) {
  const accounts = user?.accountCategories || [];
  const currentAgreements = user?.currentAgreements || [];
  const agreementsToCheck = getAgreementToCheck(accounts);

  return agreementsToCheck.every((agreement) => {
    return currentAgreements.some(({name}) => toCamelCase(name) === agreement);
  });
}

function getAgreementToCheck(accounts) {
  const isBorrowerUser = accounts.includes('borrower');
  const isLenderUser = accounts.includes('lender');
  const isContractorUser = accounts.includes('contractor');

  if (isBorrowerUser) return [...COMMON_AGREEMENTS, ...BORROWER_AGREEMENTS];
  if (isLenderUser) return [...COMMON_AGREEMENTS, ...LENDER_AGREEMENTS];
  if (isContractorUser) return [...COMMON_AGREEMENTS, ...CONTRACTOR_AGREEMENTS];

  return COMMON_AGREEMENTS;
}
