import React from 'react';

import {Route, Switch} from 'react-router-dom';

import asyncComponent from '../lib/asyncComponent';
import {account, login, verify} from '../modules/routes';
import {PublicPage} from '../modules/layout';

const Login = asyncComponent(
  () => import(/* webpackChunkName: "login" */ '../modules/login'),
);
const Change = asyncComponent(
  () => import(/* webpackChunkName: "change" */ '../modules/change'),
);
const NotFound = asyncComponent(
  () => import(/* webpackChunkName: "change" */ '../modules/not-found'),
);
const Signup = asyncComponent(
  () => import(/* webpackChunkName: "signup" */ '../modules/signup'),
);
const Reset = asyncComponent(
  () => import(/* webpackChunkName: "reset" */ '../modules/reset'),
);
const Terms = asyncComponent(
  () => import(/* webpackChunkName: "terms" */ '../modules/terms'),
);
const AccountType = asyncComponent(
  () => import(/* webpackChunkName: "account" */ '../modules/accountType'),
);
const Verify = asyncComponent(
  () => import(/* webpackChunkName: "verify" */ '../modules/verify'),
);
const TokenAuth = asyncComponent(
  () => import(/* webpackChunkName: "tokenAuth" */ '../modules/tokenAuth'),
);

export default () => (
  <PublicPage>
    <Switch>
      <Route exact path="/" component={Login} />
      <Route exact path={login()} component={Login} />
      <Route
        path="/login/updated"
        render={(props) => <Login {...props} isUpdated />}
      />
      <Route
        path="/login/invalid-token"
        render={(props) => <Login {...props} isInvalidSignupToken />}
      />
      <Route
        path="/login/expired"
        render={(props) => <Login {...props} isExpiredSignupToken />}
      />
      <Route path="/terms" component={Terms} />
      <Route path="/reset_password/:authToken" component={Change} />
      <Route exact path="/reset" component={Reset} />
      <Route
        path="/reset/expired"
        render={(props) => <Reset {...props} isExpired />}
      />
      <Route exact path="/signup/:authToken" component={Signup} />
      <Route exact path="/complete_registration/:token" component={Signup} />
      <Route exact path="/token_auth/:token" component={TokenAuth} />
      <Route path={account()} component={AccountType} />
      <Route path={verify()} component={Verify} />
      <Route component={NotFound} />
    </Switch>
  </PublicPage>
);
