import styled from '@emotion/styled';

import {AlertWithIcon} from '@renofi/components-internal';
import {maxSmall} from '@renofi/theme/src/breakpoints';

export default styled(AlertWithIcon)({
  borderRadius: 0,
  marginBottom: 0,
  fontSize: 16,
  width: '100%',
  padding: '20px 40px 20px 40px',
  [maxSmall]: {
    width: 'calc(100% + 30px)',
  },
});
