import styled from '@emotion/styled';

import {maxSmall} from '@renofi/theme/src/breakpoints';

export default styled.div({
  width: '100%',
  padding: '20px 0;',
  textAlign: 'center',
  borderBottom: `solid 1px #E5E5E5`,
  [maxSmall]: {
    width: 'calc(100% + 30px)',
    paddingTop: 20,
  },
});
