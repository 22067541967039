export default {
  translation: {
    accountType: {
      pageTitle: 'Sign in to your RenoFi account',
      warningMessage:
        'There’s more than one account associated with the above email address. Please select the account type below that you would like to access.',
      borrower: 'Borrower',
      contractor: 'Contractor',
      feasibility_reviewer: 'Feasibility reviewer',
      internal: 'Internal',
      lender: 'Lender',
    },
    mfaVerify: {
      errorMessage: 'Incorrect code.',
      pageTitle: 'Sign in to your RenoFi account',
      message:
        'Two step verification is enabled on this account. You have been emailed a 6 digit verification code to access your account. Please enter it below.',
      mfaCodeLabel: 'Enter 6 digit verification code',
      submitButton: 'Verify',
      contactUsIntro: 'Having verification problems?',
      contactUsLink: 'Contact us',
    },
    changePassword: {
      pageTitle: 'Choose a new password for your RenoFi account',
      password1Label: 'Enter a password for your RenoFi  account',
      password2Label: 'Confirm password',
      passwordsMatch: 'Passwords match',
      passwordsMisMatch: "Passwords don't match",
      returnToSignIn: 'Back to sign in',
      submitButton: 'Set new password',
    },
    passwordRules: {
      passwordRulesLengthNote: 'Your password should be at least:',
      passwordRulesLength: '8 characters long',
      passwordRulesCharactersNote:
        'and should include one or more of the following:',
      passwordRulesUppercase: 'upper case letter',
      passwordRulesLowercase: 'lower case letter',
      passwordRulesDigit: 'digit',
      passwordRulesSpecial: 'special character',
    },
    reset: {
      pageTitle: 'Requesting a new password?',
      pageTitleSuccess: 'Check your email!',
      linkExpired:
        'The link to set your new password has expired. Enter your email below and we will email you a new link to set it.',
      emailFieldTitle:
        'Enter your email address below and we’ll send you a link to set it.',
      emailFieldLabel: 'Enter your email address',
      submitButton: 'Request password link',
      requestSuccess:
        'If there’s a RenoFi account linked to this email address, we’ll send you instructions to reset your password.',
      returnToSignIn: 'Back to sign in',
    },
    login: {
      pageTitle: 'Sign in to your RenoFi account',
      emailFieldLabel: 'Enter your email',
      passwordFieldLabel: 'Enter your RenoFi password',
      submitButton: 'Sign in',
      submitError: 'Incorrect email or password.',
      isExpiredSignupToken:
        "This link has expired. Please use the new link we've sent to your inbox to signup. Contact us if you continue to experience problems.",
      isInvalidSignupToken:
        "It looks like you've already setup your account. Please use your password to login below.",
      isUpdated:
        'Your password has been successfully updated. Login using your new password below.',
      sessionExpired:
        'Your session has expired. Please log in again to continue.',
    },
    signup: {
      pageTitle: 'Choose a password for your RenoFi account',
      intro:
        'Please take a moment to create a secure password for your RenoFi account.',
      errorGeneric: 'Something went wrong. Try again later or contact support',
      errorAuthExpired: 'Authentication token expired. Please contact support',
      passwordFieldLabel: 'Enter a password for your RenoFi  account',
      submitButton: 'Create account',
    },
  },
};
