import styled from '@emotion/styled';

import {Box} from '@renofi/components-internal';
import {maxSmall} from '@renofi/theme/src/breakpoints';

export const ContentBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '60px 95px 70px 95px',
  [maxSmall]: {
    padding: '0',
    marginTop: 30,
  },
});
