import React from 'react';

import PropTypes from 'prop-types';

import {Title} from './styled';

const MainTitle = ({children}) => <Title>{children}</Title>;

MainTitle.propTypes = {
  children: PropTypes.node,
};

export default MainTitle;
