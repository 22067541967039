import React from 'react';

import PropTypes from 'prop-types';

import {Footer} from '@renofi/components-internal';

import OutdatedBrowser from '../OutdatedBrowser';

import {Column, Inner, Logo, Outer, ShadowedBox, Wrapper} from './styled';

const PublicPage = ({children}) => (
  <Wrapper>
    <OutdatedBrowser />
    <Logo />
    <Column narrow>
      <Outer>
        <ShadowedBox>
          <Inner>{children}</Inner>
        </ShadowedBox>
      </Outer>
    </Column>

    <Footer />
  </Wrapper>
);

PublicPage.propTypes = {
  children: PropTypes.node,
};

export default PublicPage;
