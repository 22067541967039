import React, {Fragment} from 'react';

import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

import Note from '../Note';

import CheckItem from './components/CheckItem';

const PasswordRules = ({errors}) => {
  const {t} = useTranslation('', {keyPrefix: 'passwordRules'});
  return (
    <Fragment>
      <Note>{t('passwordRulesLengthNote')}</Note>
      <CheckItem selected={!errors.length}>
        {t('passwordRulesLength')}
      </CheckItem>

      <Note css={{marginTop: 18}}>{t('passwordRulesCharactersNote')}</Note>
      <CheckItem selected={!errors.uppercase}>
        {t('passwordRulesUppercase')}
      </CheckItem>
      <CheckItem selected={!errors.lowercase}>
        {t('passwordRulesLowercase')}
      </CheckItem>
      <CheckItem selected={!errors.digits}>{t('passwordRulesDigit')}</CheckItem>
      <CheckItem selected={!errors.specialCharacter}>
        {t('passwordRulesSpecial')}
      </CheckItem>
    </Fragment>
  );
};

PasswordRules.propTypes = {
  errors: PropTypes.shape({
    digits: PropTypes.bool,
    length: PropTypes.bool,
    lowercase: PropTypes.bool,
    specialCharacter: PropTypes.bool,
    uppercase: PropTypes.bool,
  }),
};

export default PasswordRules;
